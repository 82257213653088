import axios from "axios"
import { BASE_URL } from "../utils/constants"
import VueCookies from 'vue-cookies'

let config = {
    headers: {
        "X-Auth-Id": VueCookies.get("X-Auth-Id"),
        // Authorization: "Bearer " + cookies.get("JWT-TOKEN"),
    }
}

export const actions = {
    async fetchSourceList({ commit }, payload) {
        commit("API_STATUS", false);
        commit("ERR_MSG", "");
        let url = "";
        if (payload) {
            url = `/hedge/fund/plans/?source=${payload.source}`
        }
        else {
            url = `/hedge/fund/plans/`
        }

        try {
            const { data } = await axios.get(`${BASE_URL}${url}`)
            commit("API_STATUS", data.status);
            if (data.status) {
                commit("SET_SOURCE_LIST", data.data)
                if (data.data && data.data.plans && payload && payload.isUpdate) {
                    commit("SET_PLAN_LIST", data.data.plans)
                }
                if (data.data && data.data.source) {
                    commit("SET_SOURCE_WALLET", data.data.source[0])
                }
            }
            if (!data.status) {
                commit("ERR_MSG", data.message)
            }
        }
        catch (e) {
            console.log(e);
            commit("ERR_MSG", e)
        }
    },

    async fetchAllPlans({ commit }) {
        commit("API_STATUS", false);
        commit("ERR_MSG", "");

        try {
            const { data } = await axios.get(`${BASE_URL}/user/all/hedge/fund/investment/`, config)
            commit("API_STATUS", data.status);
            if (data.status) {
                commit("SET_PLAN_LIST", data.data)
            }
            if (!data.status) {
                commit("SET_PLAN_LIST", [])
                commit("ERR_MSG", data.Message)
            }
        }
        catch (e) {
            console.log(e);
            commit("ERR_MSG", e)
        }
    },


    async fetchRunningInvestment({ commit }) {
        commit("API_STATUS", false);
        commit("ERR_MSG", "");

        try {
            const { data } = await axios.get(`${BASE_URL}/user/active/hedge/fund/investment/`, config)

            commit("API_STATUS", data.status);
            if (data.status) {
                commit("SET_PLAN_LIST", data.data)
            }
            if (!data.status) {
                commit("SET_PLAN_LIST", [])
                commit("ERR_MSG", data.Message)
            }
        }
        catch (e) {
            console.log(e);
            commit("ERR_MSG", e)
        }
    },

    async createHedgeFund({ commit }, payload) {
        commit("API_STATUS", false);
        commit("ERR_MSG", "");

        try {
            const { data } = await axios.post(`${BASE_URL}/hedge/fund/invest/create`, payload, config)
            commit("API_STATUS", data.result.status);
            if (!data.result.status) {
                commit("ERR_MSG", data.result.Message)
            }
        }
        catch (e) {
            console.log(e);
            commit("ERR_MSG", e)
        }
    },
}