export const mutations = {
    API_STATUS(state, payload) {
        state.apiSuccess = payload
    },
    ERR_MSG(state, payload) {
        state.errorMessage = payload
    },
    SET_SOURCE_LIST(state, payload) {
        state.sourceList = payload;
    },
    SET_PLAN_LIST(state, payload) {
        state.planList = payload;
    },
    SET_RUNNING_PLANS(state, payload) {
        state.runningPlans = payload
    },
    SET_SOURCE_WALLET(state, payload) {
        state.sourceWallet = payload
    }
}