import Vue from 'vue'
import Vuex from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiSuccess: false,
    errorMessage: "",
    planList: [],
    sourceList: null,
    runningPlans: [],
    sourceWallet: null
  },
  mutations,
  actions,
  modules: {
  }
})
