<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["apiSuccess", "errorMessage"]),
  },
  watch: {
    errorMessage() {
      if (!this.apiSuccess && this.errorMessage !== "") {
        this.$toasted.error(this.errorMessage, {
          position: "top-right",
          duration: 3000,
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";
* {
  font-size: 14px;
  color: #666;
}
</style>
