import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApexCharts from "vue-apexcharts"
import Toasted from 'vue-toasted';
import vSelect from "vue-select"
import VueCookies from 'vue-cookies'
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false
Vue.use(Toasted)
Vue.use(VueCookies)
Vue.use(VueApexCharts)
Vue.component("v-select", vSelect)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
