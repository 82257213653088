const BASE_URL = "https://crm.services-money91.com";
// const BASE_URL = "https://odoo-test.services-money91.com";

let coinsMinValues = [
  {
    ONT: 127.12,
  },
  {
    USDT: 37,
  },
  {
    BTC: 0.00116028,
  },
  {
    BTT: 33163830,
  },
  {
    DOGE: 428,
  },
];
export { BASE_URL, coinsMinValues };
