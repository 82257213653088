import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/:source',
    name: 'SourceData',
    component: () => import('../views/SourceData.vue'),
    redirect: "/:source/available-investments",
    children: [
      {
        path: "/:source/available-investments",

        component: () => import('../components/TableComponent.vue'),
      },
      {
        path: "/:source/running-investments",

        component: () => import('../components/TableComponent.vue'),
      },
      {
        path: "/:source/matured-investments",

        component: () => import('../components/TableComponent.vue'),
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/invest/:id',
    name: 'Invest',
    component: () => import('../views/Invest.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router
